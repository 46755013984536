import React from 'react';

const Index = () => {
    return (<html>
    <head>
        <title>Norsk Svindel</title>
    </head>
    <body>
    <h1>Norsk Svindell</h1>
    <p>Nettside som gir litt diverse statistikk på Norsk Tipping lotteri. Bruk informasjonen på eget ansvar🔥🙏🔥</p>
    </body>
    </html>);
};

export default Index;