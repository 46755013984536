import React from "react";

const Euro = () => {
    return (
        <div>
            <h1>
                Euro Jackpot
            </h1>
        </div>
    );
};

export default Euro;